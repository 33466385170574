<template>
  <div class="success-create" @click="emitCloseSuccess">
    <popup-base @clickOnBackground="emitCloseSuccess">
      <div class="bg-white rounded p-5 w-1/2 m-auto">
        <h3 class="text-2xl font-bold p-2">Supprimer Audience</h3>
        <div class=" p-3 rounded text-xl">
          <h3 class=" w-full font-normal success leading-10">Cette action entrainera le suppression definitive de cette audience de votre espace de publicité REMA</h3>
        </div>
        <div class="button-section mt-3 w-full">
          <button class="px-3 py-5 w-full rounded font-bold text-white text-center uppercase text-center bg-red"
                  @click="success">Supprimer</button>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
import { firebaseDb } from '../../../main'
import firebase from 'firebase'
export default {
  name: 'DeleteAudience',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  components: { PopupBase },
  methods: {
    async success () {
      await firebaseDb.ref('pubAudience').child(firebase.auth().currentUser.uid)
        .child(this.id).remove()
      this.$emit('emitSuccessCreateEnd', true)
    },
    emitCloseSuccess () {
      this.$emit('emitCloseSuccess', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .success {
    line-height: 2.3rem;
  }
</style>
