<template>
  <div class="audience-item border-t">
    <div class="audience-list-header font-bold flex w-full bg-white p-6 rounded text-base">
      <div class="flex-one w-1/3 uppercase leading-6">
        {{ data.name }}
      </div>
      <div class="flex-one w-1/3 text-right uppercase">
        {{ new Date(data.createdAt).toLocaleDateString('fr-FR') }}
      </div>
      <div
        class="flex-one w-1/3 font-light text-right text-red uppercase cursor-pointer"
        @click="wantDeleteAud = true"
      >
        {{ $t('accountNotValidCancel') }}
      </div>
    </div>
    <delete-audience
      v-if="wantDeleteAud"
      :id="data.key"
      @emitSuccessCreateEnd="wantDeleteAud = false"
      @emitCloseSuccess="wantDeleteAud = false"
    />
  </div>
</template>

<script>
import DeleteAudience from '../../components/helper/rema-components/delete-audience-warning.vue'
export default {
  name: 'Audience',
  components: {
    DeleteAudience
  },
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      wantDeleteAud: false
    }
  }
}
</script>

<style>

</style>
